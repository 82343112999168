import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { ConfirmModalComponent } from '../enter-deals/confirm-modal/confirm-modal.component';
import { ReusableConfirmationAlertComponent } from 'src/shared/components/reusable-confirmation-alert/reusable-confirmation-alert.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-affiliates',
  templateUrl: './affiliates.component.html',
  styleUrls: ['./affiliates.component.scss']
})
export class AffiliatesComponent implements OnInit {
  constructor(private dashboardService: DashboardService, private dialog: MatDialog) {}
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  public displayedColumns: string[] = [
    'emailId',
    'firstName',
    'lastName',
    'isOneAirMember',
    'memberId',
    'status',
    'source',
    'phoneNumber',
    'createDate',
    'applicationStatus'
  ];
  public searchControl: FormControl = new FormControl();
  public dataSource = new MatTableDataSource<any>([]);
  public loading: boolean = true;
  public affiliateList: any[] = [];

  public detailsOpen: boolean = false;
  public detailsData: any = {};

  ngOnInit(): void {
    this.getAllAffiliates();
  }

  public resetSearch(event: string): void {
    if (!event) {
      const result = this.affiliateList.filter((data: any) => {
        return data.emailId.toLowerCase().search('') !== -1 || data.firstName.toLowerCase().search('') !== -1 || data.lastName.toLowerCase().search('') !== -1;
      });
      this.dataSource = new MatTableDataSource<any>(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  public showDetails(item: any): void {
    this.detailsOpen = true;
    this.detailsData = item;
  }

  public getAllAffiliates(): void {
    this.dashboardService.getAllAffiliates().subscribe((res: any) => {
      this.affiliateList = res.data;
      this.dataSource = new MatTableDataSource<any>(this.affiliateList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    });
  }

  public searchItem(): void {
    const query = this.searchControl.value.toLowerCase();
    const result = this.affiliateList.filter((data: any) => {
      return data.emailId.toLowerCase().search(query) !== -1 || data.firstName.toLowerCase().search(query) !== -1 || data.lastName.toLowerCase().search(query) !== -1;
    });
    this.dataSource = new MatTableDataSource<any>(result);
    this.dataSource.paginator = this.paginator;
  }

  public updateStatusConfirmPopup(data: any, status: string): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      height: '200px',
      autoFocus: false,
      data: { body: `Are you sure you want to ${status} the affiliate?` }
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        const form = {
          oneairAffiliateId: data.oneairAffiliateId,
          status: status === 'Approve' ? 'Approved' : 'Rejected'
        };
        this.dashboardService.updateAffiliateStatus(form).subscribe(
          (res: any) => {
            this.getAllAffiliates();
          },
          (err: any) => {
            if (err.error.code === 400) {
              this.dialog.open(ReusableConfirmationAlertComponent, {
                width: '25vw',
                height: '200px',
                autoFocus: false,
                data: { message: err.error.message }
              });
            }
          }
        );
      }
    });
  }
}

