import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DashboardService } from 'src/app/core/services/dashboard.service';

@Component({
  selector: 'app-issue-ticket',
  templateUrl: './issue-ticket.component.html',
  styleUrls: ['./issue-ticket.component.scss']
})
export class IssueTicketComponent implements OnInit {
  constructor(private formBuilder: FormBuilder, private service: DashboardService, private dialog: MatDialog) {}
  public emailError: boolean = false;
  public orderIdError: boolean = false;
  public fullNameError: boolean = false;

  public form = this.formBuilder.group({
    email: [null, Validators.required],
    orderId: [null, Validators.required],
    name: [null, Validators.required]
  });

  public get email(): any {
    return this.form.get('email');
  }

  public get name(): any {
    return this.form.get('name');
  }

  public get orderId(): any {
    return this.form.get('orderId');
  }

  ngOnInit(): void {}

  public issueTicket(): void {
    if (this.form.valid) {
      this.service.issueTicket(this.form.value).subscribe(
        (res: any) => {
          const email = this.email.value;
          this.form.reset();
          this.dialog.open(AlertComponent, {
            width: '25vw',
            height: '200px',
            data: { message: `Ticket was sent to ${email}.` }
          });
        },
        (err: any) => {
          this.dialog.open(AlertComponent, {
            width: '25vw',
            height: '200px',
            data: { message: err.error.message || 'There was an issue with the server. Please try again.' }
          });
        }
      );
    } else {
      if (this.email.status) {
        this.emailError = true;
      }

      if (this.name.status) {
        this.fullNameError = true;
      }

      if (this.orderId.status) {
        this.orderIdError = true;
      }
    }
  }
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<AlertComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {}

  public close(): void {
    this.dialogRef.close();
  }
}
