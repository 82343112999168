import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivateComponent } from './private.component';
import { SharedModule } from 'src/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { HeaderComponent } from './shared/header/header.component';
import { CitiesComponent, EditCityComponent } from './cities/cities.component';
import { EditComponent, RoutesComponent } from './routes/routes.component';
import { AlertComponent, IssueTicketComponent } from './issue-ticket/issue-ticket.component';
import { EnterDealsComponent } from './enter-deals/enter-deals.component';
import { EnterDealCityComponent } from './enter-deal-city/enter-deal-city.component';
import { BookingsComponent } from './bookings/bookings.component';
import { HotelBookingsComponent } from './bookings/hotel-bookings.component';
import { FlightSearchComponent } from './flight-searches/flight-searches.component';
import { MissingRoutesComponent } from './missing-routes/missing-routes.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ConfirmModalComponent } from './enter-deals/confirm-modal/confirm-modal.component';
import { MembersComponent, ReasonComponent } from './members/members.component';
import { HomeAirportEmailComponent } from './home-airport-email/home-airport-email.component';
import { RecentMembersComponent } from './recent-members/recent-members.component';
import { AffiliatesComponent } from './affiliates/affiliates.component';
import { HotelSearchesComponent } from './hotel-searches/hotel-searches.component';

@NgModule({
  declarations: [
    PrivateComponent,
    HeaderComponent,
    CitiesComponent,
    RoutesComponent,
    EditComponent,
    EditCityComponent,
    IssueTicketComponent,
    AlertComponent,
    EnterDealsComponent,
    EnterDealCityComponent,
    BookingsComponent,
    MissingRoutesComponent,
    DashboardComponent,
    ConfirmModalComponent,
    MembersComponent,
    ReasonComponent,
    HomeAirportEmailComponent,
    RecentMembersComponent,
    FlightSearchComponent,
    HotelBookingsComponent,
    AffiliatesComponent,
    HotelSearchesComponent
  ],
  imports: [CommonModule, SharedModule, CoreModule]
})
export class PrivateModule {}
