import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-airport-email',
  templateUrl: './home-airport-email.component.html',
  styleUrls: ['./home-airport-email.component.scss']
})
export class HomeAirportEmailComponent implements OnInit {
  constructor(private formBuilder: FormBuilder, private dashboardService: DashboardService) {}
  public imageBaseUrl: string = environment.imageBaseUrl;
  public sendForm = this.formBuilder.group({
    fromCityId: [null],
    region: [null],
    emailAddresses: [''],
    cabinCode: ['Economy']
  });
  public openWhereTo: boolean = false;
  public selectedWhere: string = 'Select City';
  public selectedTo: string = 'Select Continent';
  public whereData: any = [];
  public toData: any = [];
  public regions = [
    { name: 'North America', img: '../../../../assets/images/region-north-america-1.png', selected: false },
    { name: 'Latin America', img: '../../../../assets/images/region-latin-america-1.png', selected: false },
    { name: 'Caribbean', img: '../../../../assets/images/region-caribbean-1.png', selected: false },
    { name: 'Europe', img: '../../../../assets/images/region-europe-1.png', selected: false },
    { name: 'Middle East', img: '../../../../assets/images/region-middle-east-1.png', selected: false },
    { name: 'Africa', img: '../../../../assets/images/region-africa-1.png', selected: false },
    { name: 'Asia', img: '../../../../assets/images/region-asia-1.png', selected: false },
    { name: 'Oceania', img: '../../../../assets/images/region-oceania-1.png', selected: false }
  ];
  public countryList: any = [];
  public sendSuccess: boolean = false;
  public sendError: boolean = false;
  public errorMessage: string = '';
  public filterCabinClasses: any = [
    {
      title: 'Economy',
      value: 'Y'
    },
    {
      title: 'Business Class',
      value: 'C'
    }
  ];

  ngOnInit(): void {
    this.getFromAirports();
  }

  public get fromCityId(): any {
    return this.sendForm.get('fromCityId');
  }

  public get region(): any {
    return this.sendForm.get('region');
  }

  public get emailAddresses(): any {
    return this.sendForm.get('emailAddresses');
  }

  public get cabinCode(): any {
    return this.sendForm.get('cabinCode');
  }

  public whereChange(e: any): void {
    this.selectedWhere = e.title;
    this.fromCityId.setValue(e.id);
    this.sendSuccess = false;
  }

  public toChange(e: any): void {
    this.selectedTo = e.name;
    this.region.setValue(e.name);
    this.openWhereTo = false;
    this.sendSuccess = false;
  }

  public cabinClassChange(cabin: any): void {
    this.cabinCode.setValue(cabin.title);
    this.sendSuccess = false;
  }

  public getFromAirports(): void {
    this.dashboardService.getFromAirports().subscribe((res: any) => {
      // changed function
      this.whereData = res.data
        .filter((data: any) => data.IATA_Country === 'US')
        .map((_data: any) => {
          return {
            title: `${_data.City_Name} (${_data.Airport_Code})`,
            value: _data.Airport_Code,
            id: _data.city_id
          };
        });
    });
  }

  public send(): void {
    this.sendError = false;
    this.emailAddresses.setValue(this.emailAddresses.value.replace(/\s+/g, ''));
    const cabin: any = {
      Economy: 'Y',
      'Business Class': 'C'
    };
    const form = {
      ...this.sendForm.value,
      cabinCode: cabin[this.cabinCode.value],
      emailAddresses: this.emailAddresses.value.replace(/\s+/g, '').split(',')
    };

    this.dashboardService.sendDealEmailToMember(form).subscribe(
      (res: any) => {
        this.sendSuccess = true;
        this.fromCityId.setValue(null);
        this.region.setValue(null);
        this.emailAddresses.setValue('');
        this.cabinCode.setValue('Economy');
        this.selectedWhere = 'Select City';
        this.selectedTo = 'Select Continent';
      },
      (err: any) => {
        this.sendError = true;
        this.errorMessage = err.error.message;
      }
    );
  }
}
