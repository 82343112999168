import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-reusable-button',
  templateUrl: './reusable-button.component.html',
  styleUrls: ['./reusable-button.component.scss']
})
export class ReusableButtonComponent implements OnInit {
  @Input() customContainer: string = '';
  @Input() buttonType: string = 'primary';
  @Input() buttonInput: string = 'button';
  @Input() buttonText: string = '';
  @Input() imgIcon: string = '';
  @Input() icon: string = '';
  @Output() buttonClick = new EventEmitter();
  @Input() buttonId: string = '';
  @Input() onLoading: boolean = false;
  @Input() disabled: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  public clicked(): void {
    if (!this.onLoading) {
      this.buttonClick.emit();
    }
  }
}
