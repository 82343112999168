import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import * as moment from 'moment';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';

export interface RoutesElement {
  cabin_code: string;
  cutoff_display_price: string;
  cutoff_price: string;
  fromCityName: string;
  fromCountryName: string;
  new_average_price: string;
  normal_price: string;
  route_id: number;
  toCityName: string;
  toCountryName: string;
  toRegion: string;
}

@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.scss']
})
export class RoutesComponent implements OnInit {
  constructor(private dashboardService: DashboardService, private dialog: MatDialog) {}
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public displayedColumns: string[] = [
    'fromCityName',
    'toCityName',
    'toRegion',
    'cabin_code',
    'normal_price',
    'cutoff_price',
    'cutoff_display_price',
    'min_price',
    'edit'
  ];
  public dataSource = new MatTableDataSource<RoutesElement>([]);
  public routesList: RoutesElement[] = [];
  public loading: boolean = true;

  ngOnInit(): void {
    this.getAllRoutes();
  }

  public searchControl: FormControl = new FormControl();

  private getAllRoutes(): void {
    this.dashboardService.getAllOneAirRoutes().subscribe(
      (res: any) => {
        this.routesList = res.data;
        this.dataSource = new MatTableDataSource<RoutesElement>(this.routesList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      },
      (err: any) => {
        this.loading = false;
      }
    );
  }

  public getMomentDate(date: string): string {
    return moment(date).format('MMMM DD, YYYY');
  }

  public searchItem(): void {
    const query = this.searchControl.value.toLowerCase();
    const result = this.routesList.filter((data: RoutesElement) => {
      return (
        data.fromCityName.toLowerCase().search(query) !== -1 ||
        data.toCityName.toLowerCase().search(query) !== -1 ||
        data.toRegion.toLowerCase().search(query) !== -1
      );
    });
    this.dataSource = new MatTableDataSource<RoutesElement>(result);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public editItem(element: RoutesElement): void {
    const dialogRef = this.dialog.open(EditComponent, {
      width: '50vw',
      autoFocus: false,
      disableClose: true,
      data: element
    });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.getAllRoutes();
      }
    });
  }
}

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  constructor(
    private dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data: RoutesElement,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<EditComponent>
  ) {}

  public form = this.formBuilder.group({
    normal_price: [null],
    cutoff_price: [null],
    cutoff_display_price: [null],
    route_id: [null]
  });

  public get normal_price(): any {
    return this.form.get('normal_price');
  }

  public get cutoff_price(): any {
    return this.form.get('cutoff_price');
  }

  public get cutoff_display_price(): any {
    return this.form.get('cutoff_display_price');
  }

  public get route_id(): any {
    return this.form.get('route_id');
  }

  ngOnInit(): void {
    this.route_id.setValue(this.data.route_id);
    this.cutoff_display_price.setValue(this.data.cutoff_display_price || '0.00');
    this.cutoff_price.setValue(this.data.cutoff_price || '0.00');
    this.normal_price.setValue(this.data.normal_price);
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

  public submit(): void {
    const form = {
      ...this.form.value
    };
    this.dashboardService.oneAirRouteUpdate(form).subscribe(
      (res: any) => {
        this.dialogRef.close(true);
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
}
