import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-reusable-confirmation-alert',
  templateUrl: './reusable-confirmation-alert.component.html',
  styleUrls: ['./reusable-confirmation-alert.component.scss']
})
export class ReusableConfirmationAlertComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ReusableConfirmationAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  public message: string = this.data.message;
  public yesMessage: string = this.data.yesMessage;
  public noMessage: string = this.data.noMessage;
  public hasNoButton: boolean = this.data.hasNoButton;
  public forMembership: boolean = this.data.forMembership;
  public forAirports: boolean = this.data.forAirports;
  public targetEmail: string = this.data.targetEmail;
  public showEmailLink: boolean = this.data.showEmailLink || false;

  ngOnInit(): void {}

  public onClick(event: string): void {
    this.dialogRef.close(event);
  }
}
