import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { ConfirmModalComponent } from '../enter-deals/confirm-modal/confirm-modal.component';
import { environment } from 'src/environments/environment';
import { AlertComponent } from '../issue-ticket/issue-ticket.component';
import { ReusableConfirmationAlertComponent } from 'src/shared/components/reusable-confirmation-alert/reusable-confirmation-alert.component';
import {MatSort, Sort} from '@angular/material/sort';
import {LiveAnnouncer} from '@angular/cdk/a11y';

@Component({
  selector: 'app-hotel-bookings',
  templateUrl: './hotel-bookings.component.html',
  styleUrls: ['./hotel-bookings.component.scss']
})
export class HotelBookingsComponent implements OnInit {
  constructor(private dashboardService: DashboardService, private dialog: MatDialog, private _liveAnnouncer: LiveAnnouncer) {}
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  public displayedColumns: string[] = [
    'bookingId',
    'supplierConfNumber',
    'memberId',
    'emailId',
    'cityName',
    'hotelName',
    'guestCount',
    'roomCount',
    'checkinDate',
    'checkoutDate',
    'bookingDate',
    'oneairTotalAmount',
    'bookingStatus',
    'paymentStatus',
    'supplier',
    'cancelBooking'
  ];
  public searchControl: FormControl = new FormControl();
  public dataSource = new MatTableDataSource<any>([]);
  public loading: boolean = true;
  public bookingList: any[] = [];
  public bookingListBackup: any[] = [];
  public showBookingDetails: boolean = false;
  public hotelBookingDetails: any = null;
  public hotelDetailInformation: any = {};
  public imageBaseUrl: string = environment.imageBaseUrl;
  public indiaCountry: boolean = false;

  public filterOptions: any = [
    { title: 'All', value: 'All'},
    {title: 'Cancelled', value: 'Cancelled'},
    {title: 'Confirmed', value: 'CONFIRMED'},
    {title: 'Failed', value: 'FAILED'},
  ]

  ngOnInit(): void {
    this.getAllHotelBookings();
  }

  public statusChange(value: string): void {
    if(value === 'All') {
      this.dataSource = new MatTableDataSource<any>(this.bookingListBackup);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      const result = this.bookingList.filter((data: any) => {
        return data.bookingStatus.toLowerCase() === value.toLowerCase()
      });
  
      this.dataSource = new MatTableDataSource<any>(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  public getAllHotelBookings(): void {
    this.dashboardService.getAllHotelBookings().subscribe((res: any) => {
      this.bookingList = res.data.bookings.map((d: any) => {
        return {
          ...d,
          emailId: d.member.emailId
        }
      });
      this.bookingListBackup = this.bookingList;
      this.dataSource = new MatTableDataSource<any>(this.bookingList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    });
  }

  public searchItem(): void {
    this.searchControl.setValue(this.searchControl.value.trim())
    const query = this.searchControl.value.toLowerCase();
    const result = this.bookingList.filter((data: any) => {
      return (
        data.hotelBookingId.toLowerCase().search(query) !== -1 || data.supplierConfNumber.toLowerCase().search(query) !== -1 || data.memberId == query || data.emailId.toLowerCase().search(query) !== -1
      );
    });
    this.dataSource = new MatTableDataSource<any>(result);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public resetSearch(event: string): void {
    if (!event) {
      const result = this.bookingList.filter((data: any) => {
        return data.hotelBookingId.toLowerCase().search('') !== -1 || data.supplierConfNumber.toLowerCase().search('') !== -1;
      });
      this.dataSource = new MatTableDataSource<any>(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  public announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  public cancelBooking(data: any): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      height: '200px',
      autoFocus: false,
      data: { body: `Are you sure you want to cancel booking number: ${data.hotelBookingId}?` }
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        const form = {
          memberId: data.memberId,
          hotelBookingId: data.hotelBookingId
        };
        this.dashboardService.cancelHotelBookings(form).subscribe(
          (res: any) => {
            this.getAllHotelBookings();
          },
          (err: any) => {
            if (err.error.code === 400) {
              this.dialog.open(AlertComponent, {
                width: '25vw',
                height: '200px',
                autoFocus: false,
                data: { message: err.error.message }
              });
            }
          }
        );
      }
    });
  }

  public showDetails(data: any): void {
    const { memberId, hotelBookingId } = data;
    this.dashboardService
      .getHotelBookingDetail({
        memberId,
        hotelBookingId
      })
      .subscribe((res: any) => {
        this.showBookingDetails = true;
        this.hotelBookingDetails = data;
        this.indiaCountry = this.hotelBookingDetails?.member?.memberCountryCode === 'IN' ? true : false;
        this.hotelDetailInformation = res.data;
      });
  }

  public calculateRatings(rating: string): number {
    return Math.round((Number(rating) / 5) * 100);
  }

  public getGuests(rooms: any): string {
    const guests = rooms.map((room: any) => room.guests);
    return guests
      .flat()
      .map((guest: any) => `${guest.firstName} ${guest.lastName}`)
      .toString()
      .split(',')
      .join(', ');
  }

  public setOccupants(data: any): string {
    return `${data.numOfAdults} adult${Number(data.numOfAdults) > 1 ? 's' : ''}${
      !!Number(data.numOfChildren) ? ', ' + data.numOfChildren + ' children' : ''
    }`;
  }

  public backToList(): void {
    this.showBookingDetails = false;
  }

  public bookingAction(data: any, type: string): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      height: '200px',
      autoFocus: false,
      data: { body: `Are you sure you want to ${type} booking number: ${data.hotelBookingId}?` }
    });
    const url = type === 'void' ? 'voidHotelBooking' : type === 'refund' ? 'refundHotelBooking' : 'settleTransaction';
    console.log('type', type, url);
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        const form = {
          memberId: data.memberId,
          hotelBookingId: data.hotelBookingId
        };
        this.dashboardService.bookingAction(form, url).subscribe(
          (res: any) => {
            this.getAllHotelBookings();
          },
          (err: any) => {
            if (err.error.code === 400) {
              this.dialog.open(AlertComponent, {
                width: '25vw',
                height: '200px',
                autoFocus: false,
                data: { message: err.error.message }
              });
            }
          }
        );
      }
    });
  }
}
