import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reusable-input',
  templateUrl: './reusable-input.component.html',
  styleUrls: ['./reusable-input.component.scss']
})
export class ReusableInputComponent implements OnInit {
  @Input() inputType: string = '';
  @Input() icon: string = '';
  @Input() placeholder: string = '';
  @Input() withToggle: boolean = false;
  @Input() customClass: string = '';
  @Input() control: any;
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() notRequired: boolean = false;
  @Input() maxLength!: number;
  @Input() isError: boolean = false;
  @Input() errorMessage: string = '';
  @Input() readOnly: boolean = false;
  @Output() inputChanged = new EventEmitter();
  @Output() onChange = new EventEmitter();
  @Output() onClick = new EventEmitter();

  public passwordToggle = false;

  constructor() {}

  ngOnInit(): void {}

  public passwordToggleChange(): void {
    this.passwordToggle = !this.passwordToggle;
    if (this.passwordToggle) {
      this.inputType = 'text';
    } else {
      this.inputType = 'password';
    }
  }

  public inputChange(): void {
    this.inputChanged.emit();
  }

  public modelChange(e: any): void {
    this.onChange.emit(e);
  }

  public click(e: any): void {
    this.onClick.emit(e);
  }
}
