import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  constructor(private formBuilder: FormBuilder, private router: Router, private authService: AuthService) {}

  ngOnInit(): void {}
  public signInEmailError: boolean = false;
  public signInPasswordError: boolean = false;
  public buttonContainer = 'btn-container';
  public signInNotif: boolean = false;
  public signInNotifMessage: string = '';

  public form = this.formBuilder.group({
    username: [null, Validators.required],
    password: [null, Validators.required]
  });

  public get signInEmail(): any {
    return this.form.get('username');
  }

  public get signInPassword(): any {
    return this.form.get('password');
  }

  public login(): void {
    this.signInNotif = false;
    if (this.signInEmail.errors?.required) {
      this.signInEmailError = true;
    }
    if (this.signInPassword.errors?.required) {
      this.signInPasswordError = true;
    }
    if (!this.signInEmail.errors && !this.signInPassword.errors) {
      const finalForm = {
        emailId: this.signInEmail.value,
        password: this.signInPassword.value,
        socialMediaType: ''
      };

      this.authService.login(finalForm).subscribe(
        (res: any) => {
          localStorage.setItem('credentials', JSON.stringify(res.data));
          this.router.navigate(['/dashboard']);
        },
        (err: any) => {
          this.signInNotif = true;
          this.signInNotifMessage = err.error.message;
        }
      );
    }
  }

  public inputChanged(type: string): void {
    if (type === 'signInEmail') {
      this.signInEmailError = false;
    } else if (type === 'signInPassword') {
      this.signInPasswordError = false;
    }
  }
}
