import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { ConfirmModalComponent } from '../enter-deals/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {
  constructor(private dashboardService: DashboardService, private dialog: MatDialog) {}
  public displayedColumns: string[] = [
    'memberId',
    'displayName',
    'emailId',
    'membershipLevel',
    'memberCountryCode',
    'upgradeSource',
    'upgradePaymentSource',
    'createDate',
    'accountStage',
    'status',
    'numYear',
    'vendor',
    'blockUser',
    'unblockUser',
    'downgrade',
    'delete',
    'verifiedMemberFlag'
  ];
  public searchControl: FormControl = new FormControl();
  public dataSource = new MatTableDataSource<any>([]);
  public bookingList: any[] = [];
  public isSearched: boolean = false;

  ngOnInit(): void {}

  public searchItem(): void {
    this.dashboardService.memberSearch(this.searchControl.value).subscribe(
      (res: any) => {
        this.dataSource = new MatTableDataSource<any>(res.data);
        this.isSearched = true;
      },
      (err: any) => {
        this.dataSource = new MatTableDataSource<any>([]);
        this.isSearched = true;
        console.log(err);
      }
    );
  }

  public resetSearch(event: string): void {
    if (!event) {
      this.isSearched = false;
      this.dataSource = new MatTableDataSource<any>([]);
    }
  }

  public blockUser(data: any): void {
    const dialogRef = this.dialog.open(ReasonComponent, {
      data: [data, 'block'],
      autoFocus: false,
      disableClose: true,
      width: '400px'
    });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.searchItem();
      }
    });
  }

  public downgradeUser(data: any): void {
    const dialogRef = this.dialog.open(ReasonComponent, {
      data: [data, 'downgrade'],
      autoFocus: false,
      disableClose: true,
      width: '400px'
    });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.searchItem();
      }
    });
  }

  public deleteUser(data: any): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      height: '200px',
      autoFocus: false,
      data: { body: `Are you sure you want to delete ${data.emailId}?` }
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        const form = {
          memberId: data.memberId
        };
        this.dashboardService.deleteUser(form).subscribe((res: any) => {
          this.searchItem();
        });
      }
    });
  }

  public verifyUser(data: any): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      height: '200px',
      autoFocus: false,
      data: { body: `Are you sure you want to verify ${data.emailId}?` }
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        const form = {
          memberId: data.memberId,
          verifiedMemberFlag: 1
        };
        this.dashboardService.memberVerified(form).subscribe((res: any) => {
          this.searchItem();
        });
      }
    });
  }
}

@Component({
  selector: 'app-reason',
  templateUrl: './reason.component.html',
  styleUrls: ['./reason.component.scss']
})
export class ReasonComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ReasonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dashboardService: DashboardService
  ) {}
  public reasonControl: FormControl = new FormControl();
  public reasonError: boolean = false;

  ngOnInit(): void {}

  public close(): void {
    this.dialogRef.close(false);
  }

  public submit(): void {
    this.reasonError = false;
    if (this.reasonControl.value) {
      if (this.data[1] === 'downgrade') {
        const form = {
          memberId: this.data[0].memberId,
          reason: this.reasonControl.value
        };
        this.dashboardService.deactivateRedemptionCode(form).subscribe((res: any) => {
          this.dialogRef.close(true);
        });
      } else {
        const form = {
          memberId: this.data[0].memberId,
          reason: this.reasonControl.value,
          status: this.data[0].status === 'Active' ? 'Block' : 'Active'
        };
        this.dashboardService.memberBlock(form).subscribe((res: any) => {
          this.dialogRef.close(true);
        });
      }
    } else {
      this.reasonError = true;
    }
  }
}
