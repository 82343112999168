import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { environment } from 'src/environments/environment';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-enter-deals',
  templateUrl: './enter-deals.component.html',
  styleUrls: ['./enter-deals.component.scss']
})
export class EnterDealsComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private dialog: MatDialog
  ) {}
  public showEnterDeals: boolean = false;
  public imageBaseUrl: string = environment.imageBaseUrl;
  //enter deals view
  public openWhereTo: boolean = false;
  public selectedWhere: string = 'Select City';
  public selectedTo: any = ['Select Continent'];
  public whereData: any = [];
  public toData: any = [];
  public regions = [
    { name: 'North America', img: '../../../../assets/images/region-north-america-1.png', selected: false },
    { name: 'Latin America', img: '../../../../assets/images/region-latin-america-1.png', selected: false },
    { name: 'Caribbean', img: '../../../../assets/images/region-caribbean-1.png', selected: false },
    { name: 'Europe', img: '../../../../assets/images/region-europe-1.png', selected: false },
    { name: 'Middle East', img: '../../../../assets/images/region-middle-east-1.png', selected: false },
    { name: 'Africa', img: '../../../../assets/images/region-africa-1.png', selected: false },
    { name: 'Asia', img: '../../../../assets/images/region-asia-1.png', selected: false },
    { name: 'Oceania', img: '../../../../assets/images/region-oceania-1.png', selected: false }
  ];
  public fromCityId: number = 0;
  public region: any = [];
  public cabinCode: string = 'Y';
  public countryList: any = [];
  // enter deals
  public disableBtn: boolean = true;
  public errorLogs: any = [false, false, false, false, false, false, false, false, false];
  public hasError: boolean = false;
  public cutOffPrice: number = 0;
  public fromAirports: any = [];
  public fromDropdownData: any = [];
  public toAirports: any = [];
  public toDropdownData: any = [];
  public airlinesDropdownData: any = [];
  public allAirlines: any = [];
  public airlinesSearchControl: FormControl = new FormControl();
  public travelDatesBackup: FormArray = this.formBuilder.array([]);
  public indexStopper: number = 0;
  public dealsForm = this.formBuilder.group({
    fylingFrom: [null],
    flyingTo: [null],
    cabinClass: ['Economy'],
    travelDates: this.formBuilder.array([])
  });
  public routeForm: any = {
    fromCityId: 0,
    toCityId: 0,
    cabinCode: 'Y'
  };
  public selectedFilterCabinClass: string = 'Economy';
  public buttonLoading: boolean = false;
  public filterCabinClasses: any = [
    {
      title: 'Economy',
      value: 'Y'
    },
    {
      title: 'Premium Economy',
      value: 'W'
    },
    {
      title: 'Business Class',
      value: 'C'
    },
    {
      title: 'First Class',
      value: 'F'
    }
  ];

  public stopsData: any = [
    {
      title: 'Any Stop',
      value: null
    },
    {
      title: 'Nonstop',
      value: 0
    },
    {
      title: '1 Stop',
      value: 1
    },
    {
      title: '2+ Stops',
      value: 2
    }
  ];
  public showPaste: boolean = false;
  public copyValue: any = {};

  ngOnInit(): void {
    this.generateMonths();
    this.getFromAirports();
    this.getToAirports();
    this.getAllAirlines();
  }

  public copyClicked(item: any): void {
    this.copyValue = item;
    this.showPaste = true;
  }

  public pasteClicked(index: number): void {
    this.showPaste = false;
    this.travelDates.controls[index].controls.dealPrice.setValue(this.copyValue.controls.dealPrice.value);
    this.travelDates.controls[index].controls.stops.setValue(this.copyValue.controls.stops.value);
    this.travelDates.controls[index].controls.airlines.setValue(this.copyValue.controls.airlines.value);
    this.travelDates.controls[index].controls.pasted.setValue(true);
    setTimeout(() => {
      this.travelDates.controls[index].controls.pasted.setValue(false);
    }, 1000);
  }

  public get flyingFrom(): any {
    return this.dealsForm.get('fylingFrom');
  }

  public get flyingTo(): any {
    return this.dealsForm.get('flyingTo');
  }

  public get cabinClass(): any {
    return this.dealsForm.get('cabinClass');
  }

  public get travelDates(): any {
    return this.dealsForm.get('travelDates') as FormArray;
  }

  public calculatePercentage(total: number): number {
    return Number(((80 / 100) * total).toFixed());
  }

  public generateMonths(): void {
    for (let index = 2; index < 11; index++) {
      const endOfMonth = moment().add(index, 'month').endOf('month').format('DD');
      const min = moment().add(index, 'month').format('MM/01/YYYY');
      const max = moment().add(index, 'month').format(`MM/${endOfMonth}/YYYY`);
      this.travelDates.push(
        this.formBuilder.group({
          dateRange: [null],
          dealPrice: [null],
          stops: ['Any Stop'],
          stopsValue: [null],
          airlines: [[]],
          name: moment().add(index, 'month').format('MMM YYYY'),
          min: moment(min),
          max: moment(max),
          pasted: [false]
        })
      );
      this.travelDatesBackup.push(
        this.formBuilder.group({
          dateRange: [null],
          dealPrice: [null],
          stops: ['Any Stop'],
          stopsValue: [null],
          airlines: [[]],
          name: moment().add(index, 'month').format('MMM YYYY'),
          min: moment(min),
          max: moment(max),
          pasted: [false]
        })
      );
    }
  }

  public cabinClassChange(cabin: any): void {
    this.cabinClass.setValue(cabin.title);
    this.routeForm.cabinCode = cabin.value;
    this.cabinCode = cabin.value;
    this.getCountries();
  }

  public stopsChange(e: any, controlTitle: any, controlValue: any): void {
    controlTitle.setValue(e.title);
    controlValue.setValue(e.value);
  }

  public getFromAirports(): void {
    this.dashboardService.getFromAirports().subscribe((res: any) => {
      // changed function
      this.whereData = res.data
        // .filter((data: any) => data.IATA_Country === 'US')
        .map((_data: any) => {
          return {
            title: `${_data.City_Name} (${_data.Airport_Code})`,
            value: _data.Airport_Code,
            id: _data.city_id
          };
        });
    });
  }

  public getToAirports(): void {
    this.dashboardService.getActiveArrivalCities().subscribe((res: any) => {
      this.toAirports = res.data;
    });
  }

  public fromInputChange(e: any): void {
    this.fromDropdownData = this.fromAirports
      .filter((data: any) => {
        return (
          data.City_Name.toLowerCase().search(e.toLowerCase()) !== -1 ||
          data.Airport_Code.toLowerCase().search(e.toLowerCase()) !== -1
        );
      })
      .slice(0, 5);
  }

  public selectFromAirport(airport: any): void {
    this.flyingFrom.setValue(`${airport.City_Name} (${airport.Airport_Code})`);
    this.fromDropdownData = [];
    this.routeForm.fromCityId = airport.city_id;
  }

  public toInputChange(e: any): void {
    this.toDropdownData = this.toAirports
      .filter((data: any) => {
        return (
          data.cityName.toLowerCase().search(e.toLowerCase()) !== -1 ||
          data?.location?.airportCode.toLowerCase().search(e.toLowerCase()) !== -1
        );
      })
      .slice(0, 5);
  }

  public selectToAirport(airport: any): void {
    this.flyingTo.setValue(`${airport.cityName} (${airport.location.airportCode})`);
    this.fromDropdownData = [];
    this.routeForm.toCityId = airport.cityId;
  }

  public openAirline(control: any): void {
    control.setValue(!control.value);
    this.airlinesSearchControl.setValue(null);
    this.airlinesDropdownData = [];
  }

  public closeAirline(control: any): void {
    control.setValue(false);
    this.airlinesSearchControl.setValue(null);
    this.airlinesDropdownData = [];
  }

  public getAllAirlines(): void {
    this.dashboardService.getAllAirlines().subscribe((res: any) => {
      const airlines = res.data
        .filter((_data: any) => !_data.exclude_flag)
        .map((d: any) => {
          return {
            airline_name: d.airline_name,
            airline_code: d.airline_code
          };
        });
      this.allAirlines = airlines;
      this.airlinesDropdownData = airlines.map((data: any) => `${data.airline_name} (${data.airline_code})`);
    });
  }

  public selectAirlines(airline: string[], control: any): void {
    this.allAirlines.map((data: any) => {
      if (airline.includes(`${data.airline_name} (${data.airline_code})`)) {
        const airlines = [...control.value];
        airlines.push(data.airline_code);
        const airlineCodes = [...new Set(airlines)];
        control.setValue(airlineCodes);
      }
    });
  }

  public removeAirline(airline: string, control: any): void {
    this.allAirlines.map((data: any) => {
      if (airline === `${data.airline_name} (${data.airline_code})`) {
        let airlines = [...control.value];
        airlines = airlines.filter((_data: string) => _data !== data.airline_code);
        control.setValue(airlines);
      }
    });
  }

  public searchAirline(e: any): void {
    if (e) {
      this.airlinesDropdownData = this.allAirlines
        .filter((data: any) => {
          return (
            data.airline_name.toLowerCase().search(e.toLowerCase()) !== -1 ||
            data.airline_code.toLowerCase().search(e.toLowerCase()) !== -1
          );
        })
        .slice(0, 5);
    } else {
      this.airlinesDropdownData = [];
    }
  }

  public airlineCheckboxChange(event: any, control: any): void {
    if (event.checked) {
      const airlines = [...control.value];
      airlines.push(event.source.value);
      control.setValue(airlines);
    } else {
      let airlines = [...control.value];
      airlines = airlines.filter((data: any) => data !== event.source.value);
      control.setValue(airlines);
    }
  }

  public submit(): void {
    const travelDatesForm = this.travelDates.value.map((data: any) => {
      return {
        fromDate: moment(data.dateRange.startDate._d).format('YYYY-MM-DD'),
        toDate: moment(data.dateRange.endDate._d).format('YYYY-MM-DD'),
        stops: data.stopsValue,
        price: data.dealPrice,
        airlineCodes: data.airlines
      };
    });

    const priceLowValidation = this.travelDates.value.filter((data: any) => {
      return !!data.dealPrice && data.dealPrice < this.calculatePercentage(this.cutOffPrice);
    });

    const priceHighValidation = this.travelDates.value.filter((data: any) => {
      return !!data.dealPrice && data.dealPrice > this.cutOffPrice;
    });

    if (!!priceLowValidation.length) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '25vw',
        height: '200px',
        data: { header: 'Price is too low', body: 'Are you sure you want to proceed?' }
      });
      dialogRef.afterClosed().subscribe((res: boolean) => {
        if (res) {
          this.buttonLoading = true;
          const form = {
            ...this.routeForm,
            travelDates: travelDatesForm
          };
          this.submitDeals(form);
        }
      });
    } else if (!!priceHighValidation.length) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '25vw',
        height: '230px',
        data: { header: 'Price is higher than Cut off price', body: 'Are you sure you want to proceed?' }
      });
      dialogRef.afterClosed().subscribe((res: boolean) => {
        if (res) {
          this.buttonLoading = true;
          const form = {
            ...this.routeForm,
            travelDates: travelDatesForm
          };
          this.submitDeals(form);
        }
      });
    } else {
      this.buttonLoading = true;
      const form = {
        ...this.routeForm,
        travelDates: travelDatesForm
      };
      this.submitDeals(form);
    }
  }

  public submitDeals(form: any): void {
    this.dashboardService.submitDeals(form).subscribe(
      (res: any) => {
        this.buttonLoading = false;
        this.flyingFrom.setValue(null);
        this.flyingTo.setValue(null);
        this.routeForm.toCityId = 0;
        this.routeForm.fromCityId = 0;
        this.travelDates.controls = [];
        this.generateMonths();
        this.showEnterDeals = false;
        this.getCountries();
      },
      (err: any) => {
        this.buttonLoading = false;
        alert(err.error.message);
      }
    );
  }

  public whereChange(e: any): void {
    this.selectedWhere = e.title;
    this.fromCityId = e.id;
    this.getCountries();
  }

  public toChange(e: any): void {
    if (!e.selected) {
      this.region.push(e.name);
      if (this.selectedTo[0] === 'Select Continent') {
        this.selectedTo = [e.name];
      } else {
        this.selectedTo.push(e.name);
      }
    } else {
      this.selectedTo = this.selectedTo.filter((data: string) => data !== e.name);
      this.region = this.region.filter((data: string) => data !== e.name);

      if (!this.selectedTo.length) {
        this.selectedTo = ['Select Continent'];
      }
    }
    e.selected = !e.selected;
    this.getCountries();
  }

  public getCountries(): void {
    if (!!this.fromCityId && !!this.region.length) {
      this.dashboardService.routes(this.fromCityId, this.region, this.cabinCode).subscribe(
        (res: any) => {
          this.countryList = res.data.routes;
        },
        (err: any) => {
          console.log(err);
        }
      );
    }
  }

  public countryClicked(e: any): void {
    this.cutOffPrice = e.cutoffPrice ? e.cutoffPrice : 0;
    this.flyingFrom.setValue(this.selectedWhere);
    this.flyingTo.setValue(e.tocity.cityName);
    this.routeForm.fromCityId = e.fromCityId;
    this.routeForm.toCityId = e.toCityId;
    this.showEnterDeals = true;
  }

  public cutoffChecker(dealPrice: any, index: number): boolean {
    const deal = Number(dealPrice);
    this.errorLogs[index] = !!this.cutOffPrice && deal >= this.cutOffPrice;
    setTimeout(() => {
      const res = this.errorLogs.filter((data: boolean) => data);
      this.hasError = !!res.length;
    }, 200);
    return !!this.cutOffPrice && deal >= this.cutOffPrice;
  }

  public backToDealsView(): void {
    this.travelDates.controls = [];
    this.showEnterDeals = false;
    this.disableBtn = true;
    this.generateMonths();
  }

  public calendarChange(event: any, index: number): void {
    this.indexStopper++;
    if (this.indexStopper < 19) {
      const res = {
        ...this.travelDatesBackup.controls[index].value,
        dateRange: event
      };
      this.travelDatesBackup.controls[index].setValue(res);
    }
  }

  public resetRow(item: any, index: number): void {
    item.patchValue(this.travelDatesBackup.at(index).value);
  }
}
