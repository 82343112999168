import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-reusable-input-chips',
  templateUrl: './reusable-input-chips.component.html',
  styleUrls: ['./reusable-input-chips.component.scss']
})
export class ReusableInputChipsComponent implements OnInit {
  @ViewChild('countryInput') countryInput!: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplete!: MatAutocompleteTrigger;
  @Output() onSelect = new EventEmitter();
  @Output() onRemove = new EventEmitter();
  @Input() allCountries: string[] = [];
  @Input() defaultValue: string[] = [];
  @Input() label: string = '';
  @Input() showIcon: boolean = true;
  @Input() placeholder: string = 'Enter City';
  @Input() requireRemoveFunction: boolean = false;
  @Input() defaultCode: string[] = [];
  @Input() pasted: boolean = false;
  public selectable = true;
  public removable = true;
  public countryCtrl = new FormControl();
  public filteredCountries: Observable<string[]>;
  public selectedCountries: string[] = [];
  public inputValue: string = '';
  public backupData: string[] = [];

  constructor() {
    this.filteredCountries = this.countryCtrl.valueChanges.pipe(
      startWith(null),
      map((country: string | null) => (country ? this._filter(country) : this.allCountries.slice()))
    );
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.backupData = this.allCountries;
    if (!!this.defaultValue.length) {
      this.selectedCountries = this.defaultValue;
      this.allCountries = this.allCountries.filter((d: any) => !this.defaultValue.includes(d));
    }

    if (!!this.defaultCode.length && this.pasted) {
      this.allCountries.filter((d: any) => {
        if (this.defaultCode.includes(this.getStringInsideParentheses(d))) {
          this.selected({ option: { viewValue: d } });
        }
      });
    }

    if (this.placeholder === 'Any Airlines') {
      this.selectedCountries = [];
    }
  }

  public getStringInsideParentheses(inputString: string): string {
    const regex = /\(([^)]+)\)/;
    const match = regex.exec(inputString);

    if (match && match.length > 1) {
      return match[1];
    } else {
      return '';
    }
  }

  public remove(country: string): void {
    const index = this.selectedCountries.indexOf(country);

    if (index >= 0) {
      this.selectedCountries.splice(index, 1);
      this.allCountries.push(country);
      if (this.requireRemoveFunction) {
        this.onRemove.emit(country);
      } else {
        this.onSelect.emit(this.selectedCountries);
      }
      // if (this.selectedCountries.length === 4) {
      //   this.countryInput.nativeElement.hidden = false;
      // }
    }
  }

  public selected(event: any): void {
    this.selectedCountries.push(event.option.viewValue);
    this.allCountries = this.allCountries.filter((data: any) => data !== event.option.viewValue);
    this.countryInput.nativeElement.value = '';
    this.countryCtrl.setValue(null);
    this.inputValue = '';
    this.onSelect.emit(this.selectedCountries);
    // if (this.selectedCountries.length === 5) {
    //   this.countryInput.nativeElement.hidden = true;
    // }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allCountries
      .filter((country: any) => {
        return country.toLowerCase().search(filterValue) !== -1;
      })
      .slice(0, 5);
  }

  public openPanel(event: any): void {
    this.inputValue = event.target.value;
    if (event.target.value) {
      this.inputAutoComplete.openPanel();
    } else {
      this.inputAutoComplete.closePanel();
    }
  }

  public blur(): void {
    if (this.inputValue) {
      this.inputAutoComplete.openPanel();
    } else {
      this.inputAutoComplete.autocompleteDisabled = true;
    }
  }

  public unBlur(): void {
    this.countryCtrl.setValue(null);
    this.inputValue = '';
    this.countryInput.nativeElement.value = '';
  }
}
