import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicComponent } from './public.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SharedModule } from 'src/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';

@NgModule({
  declarations: [PublicComponent, SignInComponent],
  imports: [CommonModule, SharedModule, CoreModule],
})
export class PublicModule {}
