import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) {}

  public baseUrl: string = environment.apiBaseUrl;
  public keyHex: any = CryptoJS.enc.Utf8.parse(`-----BEGIN RSA PUBLIC KEY-----
  MIIBCgKCAQEAz5ES0ThVPFuS9VWC/YOrxOmrRBADlFvphXkPltM9silmhgp7Sy1X
  RVmi3MaCmOmtOAwpNV4C1SIbvD3AXYVsxSUmrGGmnS7sFxwbc4gwOKFOJJamzqnv
  8m5T1MPBDUI5Frku3XBhecBHH/zCxoClS9BGplNI6uq9TrMDHqi9aiT6eXiA75eZ
  3qJJaqZRapQprNrkvEygFjhNNMG1AuiYxpBRefOtallpPNcBDWqz+v6blsOS9de6
  LBqa28ZH1yDSQux5fREDlee4ev7U90CK1s4g2ZB27zPRYeLw/D7wtBVbo37W3Hb1
  wRzLpdG/h098VhBXFg36TibIN0o0SvOEOQIDAQAB
  -----END RSA PUBLIC KEY-----`);

  public login(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/admin/login`;

    const encrypted = CryptoJS.DES.encrypt(JSON.stringify(form), this.keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString();

    return this.http.post(endpoint, { data: encrypted }).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public isLoggedIn(): boolean {
    return !!localStorage.getItem('credentials');
  }

  public getCredentials(): any | null {
    try {
      return JSON.parse(localStorage.getItem('credentials')!);
    } catch (error) {
      return null;
    }
  }

  public getToken(): string {
    return this.getCredentials()?.token;
  }
}
