import * as moment from 'moment';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { Component,  OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { DashboardService } from 'src/app/core/services/dashboard.service';

interface FlightSearch {
  member_id: number;
  email_id: string;
  member_country_code: string;
  membership_level: string;
  passengers: string;
  display_name: string;
  search_key: string;
  from_city_code: string;
  to_city_code: string;
  departure_date: string;
  arrival_date: string;  
  cabin_code: string;
  travel_type: string;
  created_date: string;  
}


@Component({
  selector: 'flight-searches',
  templateUrl: './flight-searches.component.html',
  styleUrls: ['./flight-searches.component.scss']
})
export class FlightSearchComponent implements OnInit {
  constructor(private dashboardService: DashboardService, private _liveAnnouncer: LiveAnnouncer) {}
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  public displayedColumns: string[] = [
    'member_id',
    'membership_level',
    'email_id',
    'member_country_code',
    'passengers',
    'display_name',
    'from_city_code',
    'to_city_code',
    'departure_date',
    'arrival_date',
    'cabin_code',
    'travel_type',
    'created_date',
  ];
  public searchControl: FormControl = new FormControl();
  public dataSource = new MatTableDataSource<FlightSearch>([]);
  public loading: boolean = true;
  public flightSearchList: FlightSearch[] = [];
  public showBookingDetails: boolean = false;
  public bookingDetails: any = {};

  ngOnInit(): void {
    this.getAllFlightSearches();
  }

  public getAllFlightSearches(): void {
    this.dashboardService.getAllFlightSearches().subscribe((res: any) => {
      this.flightSearchList = res.data;
      this.dataSource = new MatTableDataSource<FlightSearch>(this.flightSearchList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    },
    (err: any) => {
      this.loading = false;
    });
  }

  public searchItem(): void {
    this.searchControl.setValue(this.searchControl.value.trim())
    const query = this.searchControl.value.toLowerCase();
    const result = this.flightSearchList.filter((data: FlightSearch) => {
      return data.member_id?.toString().search(query) !== -1 || data.email_id?.toLowerCase().search(query) !== -1 || data.to_city_code?.toLowerCase().search(query) !== -1 || data.from_city_code?.toLowerCase().search(query) !== -1;
    });
    this.dataSource = new MatTableDataSource<FlightSearch>(result);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public resetSearch(event: string): void {
    if (!event) {
      const result = this.flightSearchList.filter((data: FlightSearch) => {
        return data.member_id.toString().toLowerCase().search('') !== -1 || data.email_id.toLowerCase().search('') !== -1 || data.to_city_code.toLowerCase().search('') !== -1 || data.from_city_code.toLowerCase().search('') !== -1;
      });
      this.dataSource = new MatTableDataSource<FlightSearch>(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  public getMomentDate(date: string): string {
    return moment(date).format('MMMM DD, YYYY');
  }

  public getMomentDateTime(date: string): string {
    return moment(date).format('MMM DD, YYYY hh:mm A');
  }

  public announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
