import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  constructor(private dashboardService: DashboardService) {}
  public membershipData: any[] = [];
  public airportData: any = [];
  public usersData: any = [];
  public view: any = [450, 300];
  // options
  public showXAxis: boolean = true;
  public showYAxis: boolean = true;
  public gradient: boolean = false;
  public showLegend: boolean = false;
  public showXAxisLabel: boolean = true;
  public xAxisLabel: string = 'Membership';
  public showYAxisLabel: boolean = true;
  public yAxisLabel: string = 'Users';
  public legendPosition: any = 'below';

  public membershipColorScheme: any = {
    domain: ['#63c2de', '#f8cb00', '#7879f1']
  };

  public userColorScheme: any = {
    domain: ['#5AA454', '#7aa3e5']
  };

  public upcomingFlightBookings: any = {
    allUpcomingTripCount: 0,
    nextWeekTripCount: 0,
    immediateTripCount: 0,
  }

  public upcomingHotelBookings: any = {
    allUpcomingTripCount: 0,
    nextWeekTripCount: 0,
    immediateTripCount: 0
  }

  ngOnInit(): void {
    this.getStatistics();
  }

  public getStatistics(): void {
    this.dashboardService.getStatistics().subscribe((res: any) => {
      this.upcomingFlightBookings = res.data.flights;
      this.upcomingHotelBookings = res.data.hotels;

      this.usersData = [
        {
          name: `Total users: ${res.data.totalMember}`,
          value: res.data.totalMember
        },
        {
          name: `User registered last 24 hours: ${res.data.registerMemberInLast24}`,
          value: res.data.registerMemberInLast24
        }
      ];

      this.membershipData = res.data.memberByMembership.map((data: any) => {
        return {
          name: `${data.membershipLevel}: ${data.count}`,
          value: data.count
        };
      });

      this.airportData = res.data.memberByAirport.map((data: any) => {
        return {
          name: data.airportCode,
          value: data.count
        };
      });
    });
  }
}
