import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lakhNumber'
})
export class IndiaCurrency implements PipeTransform {
  constructor() {}
  transform(num: string | number) {
    const number = typeof num === 'string' ? parseFloat(num) : num;
    return Number(number.toFixed(0)).toLocaleString('en-IN');
  }
}
