import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';

@Component({
  selector: 'app-enter-deal-city',
  templateUrl: './enter-deal-city.component.html',
  styleUrls: ['./enter-deal-city.component.scss']
})
export class EnterDealCityComponent implements OnInit {
  constructor(private dashboardService: DashboardService) {}
  public cityList: any = [];
  public whereData: any = [];
  public selectedWhere: string = 'Select City';
  public toCityIds: any = [];
  public fromCityId: number = 0;
  public allCities: any = [];
  public selectedCabin: string = 'Y';
  public filterCabinClasses: any = [
    {
      title: 'Economy',
      value: 'Y'
    },
    {
      title: 'Premium Economy',
      value: 'W'
    },
    {
      title: 'Business Class',
      value: 'C'
    },
    {
      title: 'First Class',
      value: 'F'
    }
  ];

  ngOnInit(): void {
    this.getAllCities();
    this.getFromAirports();
  }

  public selectCountries(countries: string[]): void {
    this.allCities.map((data: any) => {
      if (countries.includes(`${data.city_name}, ${data.Name}`)) {
        const newCityIds = [...this.toCityIds];
        newCityIds.push(data.city_id);
        this.toCityIds = newCityIds;
      }
    });

    const newCityIds = [...new Set(this.toCityIds)];

    this.toCityIds = newCityIds;
    if (!countries.length) {
      this.toCityIds = [];
    }
  }

  private getAllCities(): void {
    this.dashboardService.getAllCities().subscribe((res: any) => {
      this.allCities = res.data;
      this.cityList = res.data.map((data: any) => `${data.city_name}, ${data.Name}`);
    });
  }

  public getFromAirports(): void {
    this.dashboardService.getFromAirports().subscribe((res: any) => {
      this.whereData = res.data
        // .filter((data: any) => data.IATA_Country === 'US')
        .map((_data: any) => {
          return {
            title: `${_data.City_Name} (${_data.Airport_Code})`,
            value: _data.Airport_Code,
            id: _data.city_id
          };
        });
    });
  }

  public whereChange(e: any): void {
    this.selectedWhere = e.title;
    this.fromCityId = e.id;
  }

  public cabinChange(e: string): void {
    this.selectedCabin = e;
  }

  public save(): void {
    const form = {
      fromCityId: this.fromCityId,
      toCityIds: this.toCityIds,
      cabinCode: this.selectedCabin
    };

    this.dashboardService.updateRoute(form).subscribe(
      (res: any) => {
        window.location.reload();
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
}
