import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-reusable-dropdown',
  templateUrl: './reusable-dropdown.component.html',
  styleUrls: ['./reusable-dropdown.component.scss']
})
export class ReusableDropdownComponent implements OnInit {
  constructor() {}
  @Input() dropdownData: any = [];
  @Output() onChange = new EventEmitter();
  @Input() initialValue: string = '';
  @Input() label: string = '';

  ngOnInit(): void {}

  public change(e: any): void {
    this.onChange.emit(e.value);
    this.initialValue = e.title;
  }
}
