import { MatDialog } from '@angular/material/dialog';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AlertComponent } from 'src/app/features/private/issue-ticket/issue-ticket.component';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private dialog: MatDialog, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.authService.getToken();

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          Accept: 'application/json'
        }
      });
    }

    return next.handle(request).pipe(
      tap(
        (res) => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            const serverErrorCode = [500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511];
            if (err.status === 401) {
              localStorage.removeItem('credentials');
              this.router.navigate(['/sign-in']);
            } else if (serverErrorCode.includes(err.status)) {
              this.dialog.open(AlertComponent, {
                width: '25vw',
                height: '200px',
                data: { message: 'There was an issue with the server. Please try again.' }
              });
            }
          }
        }
      )
    );
  }
}
