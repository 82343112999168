import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-missing-routes',
  templateUrl: './missing-routes.component.html',
  styleUrls: ['./missing-routes.component.scss']
})
export class MissingRoutesComponent implements OnInit {
  constructor(private dashboardService: DashboardService, private dialog: MatDialog) {}
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public displayedColumns: string[] = [
    'fromCityName',
    'toCityName',
    'ToCountry',
    'toRegion',
    'cabin_code',
    'publish_date',
    'normal_price',
    'cutoff_price',
    'cutoff_display_price',
    'min_deal_price'
  ];
  public dataSource = new MatTableDataSource<any>([]);
  public routesList: any[] = [];
  public loading: boolean = true;

  ngOnInit(): void {
    this.getAllMissingRoutes();
  }

  public searchControl: FormControl = new FormControl();

  private getAllMissingRoutes(): void {
    this.dashboardService.getAllMissingRoutes().subscribe(
      (res: any) => {
        this.routesList = res.data;
        this.dataSource = new MatTableDataSource<any>(this.routesList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      },
      (err: any) => {
        this.loading = false;
      }
    );
  }

  public getMomentDate(date: string): string {
    return moment(date).format('MMMM DD, YYYY');
  }

  public searchItem(): void {
    const query = this.searchControl.value.toLowerCase();
    const result = this.routesList.filter((data: any) => {
      return (
        data.fromCityName.toLowerCase().search(query) !== -1 ||
        data.toCityName.toLowerCase().search(query) !== -1 ||
        data.toRegion.toLowerCase().search(query) !== -1 ||
        data.ToCountry.toLowerCase().search(query) !== -1
      );
    });
    this.dataSource = new MatTableDataSource<any>(result);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
