import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/core/services/dashboard.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor(private router: Router, private dashboardService: DashboardService) {}

  ngOnInit(): void {}

  public refresh(): void {
    this.router.navigate(['/dashboard']);
  }

  public logout(): void {
    localStorage.clear();
    window.location.reload();
  }

  public triggerAirTicketScheduler(): void {
    this.dashboardService.triggerAirTicketScheduler().subscribe((res: any) => {});
  }
}
