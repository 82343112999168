import { FormBuilder, FormControl } from '@angular/forms';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CityElement {
  city_id: number;
  city_name: string;
  city_code: string;
  image_url: string;
  planned_from_city: number;
  planned_to_city: number;
  active_from_city: number;
  active_to_city: number;
  x_position: string;
  y_position: string;
  member_from_city: number;
  current_temp: string;
  country_code: string;
  large_image_url: string;
  Default_city_tier: string;
  old_image_url: string;
  create_date: string;
  update_date: string;
  Name: string;
}

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss']
})
export class CitiesComponent implements OnInit {
  constructor(private dashboardService: DashboardService, private dialog: MatDialog) {}
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  public displayedColumns: string[] = [
    'city_id',
    'city_name',
    'Name',
    'country_code',
    'active_from_city',
    'active_to_city',
    'member_from_city',
    'planned_from_city',
    'planned_to_city',
    'edit'
  ];
  public searchControl: FormControl = new FormControl();
  public dataSource = new MatTableDataSource<CityElement>([]);
  public loading: boolean = true;
  public cityList: CityElement[] = [];

  ngOnInit(): void {
    this.getAllCities();
  }

  private getAllCities(): void {
    this.dashboardService.getAllCities().subscribe(
      (res: any) => {
        this.cityList = res.data;
        this.dataSource = new MatTableDataSource<CityElement>(this.cityList);
        this.dataSource.paginator = this.paginator;
        this.loading = false;
      },
      (err: any) => {
        this.loading = false;
      }
    );
  }

  public searchItem(): void {
    const query = this.searchControl.value.toLowerCase();
    const result = this.cityList.filter((data: CityElement) => {
      return data?.city_name?.toLowerCase().search(query) !== -1 || data?.Name?.toLowerCase().search(query) !== -1;
    });
    this.dataSource = new MatTableDataSource<CityElement>(result);
    this.dataSource.paginator = this.paginator;
  }

  public editItem(element: CityElement): void {
    const dialogRef = this.dialog.open(EditCityComponent, {
      width: '30vw',
      autoFocus: false,
      disableClose: true,
      data: element
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.getAllCities();
      }
    });
  }
}

@Component({
  selector: 'app-edit-city',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditCityComponent implements OnInit {
  constructor(
    private dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data: CityElement,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<EditCityComponent>
  ) {}

  public form = this.formBuilder.group({
    plannedFromCity: [null],
    plannedToCity: [null],
    activeFromCity: [null],
    activeToCity: [null],
    cityId: [null]
  });

  public get plannedFromCity(): any {
    return this.form.get('plannedFromCity');
  }

  public get plannedToCity(): any {
    return this.form.get('plannedToCity');
  }

  public get activeFromCity(): any {
    return this.form.get('activeFromCity');
  }

  public get activeToCity(): any {
    return this.form.get('activeToCity');
  }

  public get cityId(): any {
    return this.form.get('cityId');
  }

  ngOnInit(): void {
    this.plannedFromCity.setValue(!!this.data.planned_from_city);
    this.plannedToCity.setValue(!!this.data.planned_to_city);
    this.activeFromCity.setValue(!!this.data.active_from_city);
    this.activeToCity.setValue(!!this.data.active_to_city);
    this.cityId.setValue(this.data.city_id);
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

  public submit(): void {
    const form = {
      ...this.form.value,
      plannedFromCity: this.plannedFromCity.value ? 1 : 0,
      plannedToCity: this.plannedToCity.value ? 1 : 0,
      activeFromCity: this.activeFromCity.value ? 1 : 0,
      activeToCity: this.activeToCity.value ? 1 : 0
    };
    this.dashboardService.cityUpdate(form).subscribe(
      (res: any) => {
        this.dialogRef.close(true);
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
}
